import React from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import DifferenceValue from '../DifferenceValue'
import { selectComparisonBoardOption } from '../../../../state/comparisonBoard/selectors'
import {
  RPM_COMPARISON_BOARD_OPTION_DIFFERENCES,
  RPM_COMPARISON_BOARD_OPTION_FULL_DATA,
} from '../../../../consts/comparisonBoard'
import WeightValue from '../WeightValue'
import nodeDefinitionTypeMap from '../../../../../../components/scoring_tree/helper/nodeDefinitionTypeMap'
import ScoreContributionValue from '../ScoreContributionValue'
import DeltaContributionValue from '../DeltaContributionValue'

const classNames = require('classnames')

function BottomRow({ node, nodeDefinition, differences, fullData, product }) {
  const { type } = nodeDefinition

  return (
    <div
      className={classNames('score-card__bottom', {
        'score-card__bottom--differences': differences,
        'score-card__bottom--full-data': fullData,
      })}
    >
      {differences && <DifferenceValue node={node} nodeDefinition={nodeDefinition} product={product} />}

      {fullData && (
        <>
          {type === nodeDefinitionTypeMap.criterion && (
            <ScoreContributionValue nodeDefinition={nodeDefinition} node={node} />
          )}
          {type === nodeDefinitionTypeMap.criterion && (
            <DeltaContributionValue nodeDefinition={nodeDefinition} node={node} />
          )}
          {type === nodeDefinitionTypeMap.item && (
            <ScoreContributionValue nodeDefinition={nodeDefinition} node={node} />
          )}
          <WeightValue nodeDefinition={nodeDefinition} node={node} />
        </>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    differences: selectComparisonBoardOption(state, RPM_COMPARISON_BOARD_OPTION_DIFFERENCES),
    fullData: selectComparisonBoardOption(state, RPM_COMPARISON_BOARD_OPTION_FULL_DATA),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(BottomRow)
