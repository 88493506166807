import classNames from 'classnames'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { toFixed1IfDecimal } from '../../../../../../va-corejs-v3/utils'
import nodeDefinitionTypeMap from '../../../../../components/scoring_tree/helper/nodeDefinitionTypeMap'
import { RPM_COMPARISON_BOARD_OPTION_DIFFERENCES } from '../../../consts/comparisonBoard'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import {
  selectComparisonBoardOption,
  selectComparisonBoardReferenceProduct,
  selectComparisonBoardProductTemplate,
  selectProductCalculatedWeights,
} from '../../../state/comparisonBoard/selectors'
import { calcScoreContribution } from '../../../services/product'

function DifferenceValue({
  node,
  nodeDefinition,
  product,
  referenceProduct,
  differences,
  template,
  calculatedWeights,
  refCalculatedWeights,
}) {
  const { type, bonus_demerit: bonusDemerit } = nodeDefinition

  const referenceNode = useMemo(() => {
    if (!referenceProduct || !differences) {
      return null
    }

    return _.find(referenceProduct.nodes, _node => _node.node_definition_id === node.node_definition_id)
  }, [referenceProduct, differences, node])

  const differenceValue = useMemo(() => {
    const { node_definitions: nodeDefinitions } = template
    const contribution = calcScoreContribution(calculatedWeights, nodeDefinition, nodeDefinitions, product)
    const refContribution = calcScoreContribution(
      refCalculatedWeights,
      nodeDefinition,
      nodeDefinitions,
      referenceProduct
    )

    if (!referenceNode) {
      return null
    }

    if (referenceNode.id === node.id) {
      // Return null if the node is the current reference node
      return null
    }

    if (
      (bonusDemerit && type !== nodeDefinitionTypeMap.criterion) ||
      type === nodeDefinitionTypeMap.perimeter ||
      type === nodeDefinitionTypeMap.family
    ) {
      return null
    }

    return toFixed1IfDecimal(contribution - refContribution)
  }, [referenceNode, calculatedWeights, refCalculatedWeights])

  const differenceSign = useMemo(() => {
    if (differenceValue === null) {
      return null
    }

    if (differenceValue === 0) {
      return ''
    }

    return differenceValue > 0 ? '+' : '' // Minus is already included in the number
  }, [differenceValue])

  if (differenceValue === null) {
    return null
  }

  return (
    <div
      className={classNames('comparison-board-table__score-difference', {
        'score__difference--positive': differenceValue >= 0,
        'score__difference--negative': differenceValue < 0,
      })}
    >
      {`${differenceSign}${differenceValue}`}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    texts: state.texts.values,
    environment: state.environment,
    differences: selectComparisonBoardOption(state, RPM_COMPARISON_BOARD_OPTION_DIFFERENCES),
    referenceProduct: selectComparisonBoardReferenceProduct(state),
    template: selectComparisonBoardProductTemplate(state),
    calculatedWeights: selectProductCalculatedWeights(state, ownProps.product.id),
    refCalculatedWeights: selectProductCalculatedWeights(state, selectComparisonBoardReferenceProduct(state)?.id),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(DifferenceValue)
