import React from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import { selectNodeCalculatedWeight } from '../../../state/comparisonBoard/selectors'
import DeltaIcon from '../../../../../assets/svg/triangle.svg'
import { calcDeltaScoreContribution } from '../../../services/product'

const classNames = require('classnames')

function DeltaContibutionValue({ className, calculatedWeight }) {
  const value = calculatedWeight ? calcDeltaScoreContribution(calculatedWeight)?.toFixed(2) : 'n.a.'

  return (
    <div className={classNames(className, 'comparison-board-table__delta-score-contribution')}>
      <div className="comparison-board-table__delta-score-contribution-icon">
        <DeltaIcon width="12px" />
      </div>
      <div className="comparison-board-table__delta-score-contribution-value">{value}</div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    texts: state.texts.values,
    environment: state.environment,
    calculatedWeight: selectNodeCalculatedWeight(state, ownProps.node.product_id, ownProps.node.node_definition_id),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(DeltaContibutionValue)
