import { createSelector } from 'reselect'
import _ from 'lodash'

const selectOptions = state => state.renaultProjectMode.comparisonBoard.options
const selectProducts = state => state.renaultProjectMode.comparisonBoard.productsData
const selectProductsOrder = state => state.renaultProjectMode.comparisonBoard.productsOrder
const selectExpandedRows = state => state.renaultProjectMode.comparisonBoard.expandedRows
const selectExpandedModelChangeRows = state => state.renaultProjectMode.comparisonBoard.expandedModelChangeRows
const selectTemplates = state => state.templates.list
const selectNodeModal = state => state.renaultProjectMode.comparisonBoard.nodeModal
const selectEditedNodes = state => state.renaultProjectMode.comparisonBoard.editedNodes
const selectCalculatedWeights = state => state.renaultProjectMode.comparisonBoard.calculatedWeights

export const selectComparisonBoardProducts = createSelector([selectProducts, selectProductsOrder], (products, order) =>
  _.map(order, id => products[id])
)

export const selectComparisonBoardProductById = createSelector(
  [selectProducts, (state, id) => id],
  (products, id) => products[id]
)

export const selectComparisonBoardReferenceProduct = createSelector([selectProducts], products =>
  _.find(products, product => product.reference)
)

export const selectComparisonBoardOption = createSelector([selectOptions, (state, value) => value], (options, value) =>
  _.get(options, value, null)
)

export const selectComparisonBoardProductTemplate = createSelector(
  [selectTemplates, selectProducts],
  (templates, products) => {
    const firstProduct = _.first(_.values(products))

    if (!firstProduct) {
      return null
    }

    const { template_id: templateId } = firstProduct
    return _.find(templates, item => item.id === templateId)
  }
)

export const selectProductCalculatedWeights = createSelector(
  [selectCalculatedWeights, (_1, productId) => productId],
  (calculatedWeights, productId) => {
    return _.get(calculatedWeights, productId, {})
  }
)

export const selectNodeCalculatedWeight = createSelector(
  [selectCalculatedWeights, (_1, productId) => productId, (_2, _3, nodeDefinitionId) => nodeDefinitionId],
  (calculatedWeights, productId, nodeDefinitionId) => {
    const weights = _.get(calculatedWeights, productId, {})
    return _.get(weights, nodeDefinitionId, null)
  }
)

export const selectIsAllExpanded = createSelector(
  [selectComparisonBoardProductTemplate, selectExpandedRows],
  (template, expandedRows) => {
    if (!template) {
      return false
    }

    const nodeDefinitions = _.filter(
      template.node_definitions,
      nodeDefinition => nodeDefinition._right - nodeDefinition._left > 1
    )

    return nodeDefinitions.length === expandedRows.length
  }
)

export const selectNode = createSelector(
  [selectProducts, (state, productId) => productId, (state, productId, nodeDefinitionId) => nodeDefinitionId],
  (products, productId, nodeDefinitionId) => {
    const product = _.find(products, _product => _product.product_id === productId)

    if (!product) {
      return null
    }

    const { nodes } = product

    return nodes ? _.find(nodes, _node => _node.node_definition_id === nodeDefinitionId) : null
  }
)

export const selectIsExpandedComparisonBoardRow = createSelector(
  [selectExpandedRows, (state, nodeDefinitionId) => nodeDefinitionId],
  (expandedRows, nodeDefinitionId) => expandedRows.includes(nodeDefinitionId)
)

export const selectIsExpandedModelChangeRow = createSelector(
  [selectExpandedModelChangeRows, (state, nodeDefinitionId) => nodeDefinitionId],
  (expandedRows, nodeDefinitionId) => expandedRows?.includes(nodeDefinitionId)
)

export const selectNodeDefinitionForModal = createSelector(
  [selectComparisonBoardProductTemplate, selectNodeModal],
  (template, nodeModal) => {
    const { nodeDefinitionId } = nodeModal

    if (!nodeDefinitionId) {
      return null
    }

    return _.find(template.node_definitions, _node => _node.id === nodeDefinitionId)
  }
)

export const selectEditedNodesByProductId = createSelector(
  [selectEditedNodes, (state, productId) => productId],
  (editedNodes, productId) => _.get(editedNodes, productId, [])
)
