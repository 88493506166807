/* eslint-disable consistent-return */
import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import {
  selectComparisonBoardProductById,
  selectComparisonBoardProductTemplate,
  selectProductCalculatedWeights,
} from '../../../state/comparisonBoard/selectors'
import nodeDefinitionTypeMap from '../../../../../components/scoring_tree/helper/nodeDefinitionTypeMap'
import { calcScoreContribution } from '../../../services/product'

const classNames = require('classnames')

function ScoreContributionValue({ className, nodeDefinition, calculatedWeights, template, product }) {
  if (!nodeDefinition) return <></>

  const value = calcScoreContribution(calculatedWeights, nodeDefinition, template.node_definitions, product)

  const roundedValue = useMemo(() => {
    const _value = Math.round((value + Number.EPSILON) * 100) / 100
    return Number.isInteger(_value) ? `${_value}.00` : _value
  }, [value])

  const nodeDefinitions = template.node_definitions
  const family = Object.values(nodeDefinitions).find(
    nd =>
      nd._left < nodeDefinition._left && nd._right > nodeDefinition._right && nd.type === nodeDefinitionTypeMap.family
  )
  const familyNormalizedScore = family?.normalized_score ?? 500

  // Score contribution in percentage
  const roundedPercentage = useMemo(() => {
    const percentage = (value * 100) / familyNormalizedScore
    return value ? `${Math.round((percentage + Number.EPSILON) * 100) / 100}%` : `0%`
  }, [value, familyNormalizedScore])

  return (
    <div className={classNames(className, 'comparison-board-table__score-contribution')}>
      <span className="comparison-board-table__score-contribution-value">{roundedValue}</span>
      {nodeDefinition?.type === nodeDefinitionTypeMap.item && <span> - {roundedPercentage}</span>}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    texts: state.texts.values,
    environment: state.environment,
    template: selectComparisonBoardProductTemplate(state),
    calculatedWeights: selectProductCalculatedWeights(state, ownProps.node.product_id),
    product: selectComparisonBoardProductById(state, ownProps.node.product_id),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ScoreContributionValue)
