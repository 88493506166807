import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import nodeDefinitionTypeMap from '../../../../../../components/scoring_tree/helper/nodeDefinitionTypeMap'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import { selectNodeMedia } from '../../../../state/products/selectors'
import CommentsOutlineIcon from '../../../../../../assets/svg/comments-outline.svg'
import MediaOutlineIcon from '../../../../../../assets/svg/media-outline.svg'
import FlagIcon from '../../../../../../assets/svg/selected-flag-icon.svg'
import ScoreSelector from './ScoreSelector'
import {
  selectComparisonBoardProductTemplate,
  selectEditedNodesByProductId,
} from '../../../../state/comparisonBoard/selectors'
import BottomRow from './BottomRow'
import ScoreContributionValue from '../ScoreContributionValue'
import { calcNodeScore } from '../../../../services/product'
import { isProductUpdatable } from '../../../../services/comparisonBoard'
import CommentsPopup from './CommentsPopup'
import CommentModal from './CommentModal'

const classNames = require('classnames')

function ScoreCard({
  node,
  nodeDefinition,
  product,
  media,
  template,
  editedNodes,
  updateComparisonBoardNodeAttributes,
}) {
  const { type, bonus_demerit: bonusDemerit } = nodeDefinition
  const { is_default: isDefault, is_enabled: isEnabled, board_to_review: comparisonToReview } = node
  const { product_id: productId } = product
  const [selectorAnchorEl, setSelectorAnchorEl] = useState(null)
  const selectorOpen = Boolean(selectorAnchorEl)
  const isEdited = useMemo(() => editedNodes.includes(node.id), [editedNodes])
  const score = useMemo(() => calcNodeScore(node, nodeDefinition, template, product), [node])

  const [openCommentModal, setOpenCommentModal] = useState(false)
  const [commentToEdit, setCommentToEdit] = useState(false)
  const [toReview, setToReview] = useState(comparisonToReview ?? false)

  const classSuffix = useMemo(() => {
    if (type === nodeDefinitionTypeMap.criterion) {
      return score < 0 ? `n${Math.abs(score)}` : score
    }

    return ''
  }, [type, score])

  if (!isEnabled) {
    return (
      <div className="comparison-board-table__score-card-wrapper">
        <div className="comparison-board-table__score-card" />
      </div>
    )
  }

  const toggleToReview = () => {
    if (toReview) {
      // TO DO: remove to review action
      updateComparisonBoardNodeAttributes(productId, node.id, { board_to_review: false })
    } else {
      // TO DO: add to review action
      updateComparisonBoardNodeAttributes(productId, node.id, { board_to_review: true })
    }
    setToReview(!toReview)
  }

  return (
    <div
      className={classNames('comparison-board-table__score-card-wrapper', {
        'comparison-board-table__score-card-wrapper--enabled': isEdited,
      })}
    >
      <div className="comparison-board-table__score-card">
        <div className="score-card__top">
          {type === nodeDefinitionTypeMap.item && <ScoreContributionValue className="score-card__score" node={node} />}

          {type === nodeDefinitionTypeMap.criterion && (
            <div className="score-card__score">
              <button
                type="button"
                className={classNames(
                  'score-card__score-button',
                  'product-score__button',
                  'product-score__stamp',
                  `product-score__stamp--score-${classSuffix}`,
                  {
                    'product-score__stamp--bonus-demerit': bonusDemerit,
                    'product-score__stamp--default': isDefault,
                    'product-score__button--disabled': !isProductUpdatable(product),
                  }
                )}
                disabled={!isProductUpdatable(product)}
                onClick={e => setSelectorAnchorEl(e.currentTarget)}
              >
                {score}
              </button>
            </div>
          )}

          <div className="score-card-counters">
            <div
              className={`score-card-button-to-review ${toReview ? 'to-review' : ''}`}
              onClick={() => toggleToReview()}
              onKeyPress={() => {}}
              role="button"
              tabIndex={0}
            >
              <FlagIcon width="12" height="13" />
            </div>

            {node.comments && (
              <div className="score-card-counter score-card-counter--comments">
                <div className="score-card-counter__icon">
                  <CommentsOutlineIcon width="11" />
                </div>
                <span className="score-card-counter__value">{node.comments.length}</span>
              </div>
            )}

            {media && (
              <div className="score-card-counter score-card-counter--media">
                <div className="score-card-counter__icon">
                  <MediaOutlineIcon width="15" />
                </div>
                <span className="score-card-counter__value">{media.length}</span>
              </div>
            )}
          </div>
        </div>
        <BottomRow node={node} nodeDefinition={nodeDefinition} product={product} />
      </div>

      {selectorOpen && (
        <ScoreSelector
          nodeId={node.id}
          productId={productId}
          bonusDemerit={bonusDemerit}
          onClickAway={() => setSelectorAnchorEl(null)}
          anchorEl={selectorAnchorEl}
        />
      )}
      {node.comments && (node.comments.length > 0 || isProductUpdatable(product)) && (
        <CommentsPopup
          isEditable={isProductUpdatable(product)}
          setOpenCommentModal={setOpenCommentModal}
          setCommentToEdit={setCommentToEdit}
          comments={node.comments}
        />
      )}
      <CommentModal
        comment={commentToEdit}
        node={node}
        nodeDefinition={nodeDefinition}
        product={product}
        open={openCommentModal}
        setOpen={setOpenCommentModal}
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    texts: state.texts.values,
    media: selectNodeMedia(state, ownProps.product.product_id, ownProps.node.id),
    template: selectComparisonBoardProductTemplate(state),
    editedNodes: selectEditedNodesByProductId(state, ownProps.product.product_id),
    environment: state.environment,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ScoreCard)
