import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { toFixed1IfDecimal } from '../../../../../../va-corejs-v3/utils'
import nodeDefinitionTypeMap from '../../../../../components/scoring_tree/helper/nodeDefinitionTypeMap'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import {
  selectComparisonBoardOption,
  selectComparisonBoardProductTemplate,
  selectNode,
  selectProductCalculatedWeights,
} from '../../../state/comparisonBoard/selectors'
import DifferenceValue from './DifferenceValue'
import ScoreCard from './ScoreCard'
import { calcScoreContribution } from '../../../services/product'
import {
  RPM_COMPARISON_BOARD_OPTION_DIFFERENCES,
  RPM_COMPARISON_BOARD_OPTION_FULL_DATA,
} from '../../../consts/comparisonBoard'

function ScoreCell({ node, nodeDefinition, product, template, calculatedWeights, fullDataOption, differencesOption }) {
  const { type } = nodeDefinition
  const nodeDefinitions = template.node_definitions
  const family = Object.values(nodeDefinitions).find(
    nd =>
      nd._left < nodeDefinition._left && nd._right > nodeDefinition._right && nd.type === nodeDefinitionTypeMap.family
  )
  const familyNormalizedScore = family?.normalized_score ?? 500

  const value = useMemo(() => {
    if (!node) {
      return null
    }

    if (type === nodeDefinitionTypeMap.perimeter || !node?.is_enabled) {
      return ''
    }

    if (type === nodeDefinitionTypeMap.family || (!fullDataOption && !differencesOption)) {
      return node.normalized_score
    }

    return calcScoreContribution(calculatedWeights, nodeDefinition, nodeDefinitions, product)
  }, [node, nodeDefinition, calculatedWeights])

  const roundedValue = useMemo(() => {
    // eslint-disable-next-line eqeqeq
    if (typeof value != 'number') return ''

    const _value = Math.round((value + Number.EPSILON) * 10) / 10

    if (type === 'family') return toFixed1IfDecimal(_value)

    return _value
  }, [value])

  // Score contribution in percentage
  const roundedPercentage = useMemo(() => {
    // eslint-disable-next-line eqeqeq
    if (typeof value != 'number') return ''
    const percentage = (value * 100) / familyNormalizedScore
    return value ? `${Math.round((percentage + Number.EPSILON) * 10) / 10}%` : `0%`
  }, [value, familyNormalizedScore])

  if (node && (type === nodeDefinitionTypeMap.item || type === nodeDefinitionTypeMap.criterion)) {
    return <ScoreCard product={product} nodeDefinition={nodeDefinition} node={node} />
  }

  return (
    <div
      className="comparison-board-table__score"
      style={{
        justifyContent: type !== 'family' && differencesOption && !product.reference ? 'flex-start' : null,
        paddingLeft: type !== 'family' && differencesOption && !product.reference ? '14px' : null,
      }}
    >
      {node?.is_enabled && (
        <>
          <div className="score__value">{roundedValue}</div>
          {type !== nodeDefinitionTypeMap.perimeter && (
            <>
              <span style={{ marginLeft: '3px', marginRight: '3px' }}>-</span>
              <span>{roundedPercentage}</span>
            </>
          )}
          <DifferenceValue node={node} nodeDefinition={nodeDefinition} product={product} />
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    texts: state.texts.values,
    environment: state.environment,
    node: selectNode(state, ownProps.product.product_id, ownProps.nodeDefinition.id),
    template: selectComparisonBoardProductTemplate(state),
    calculatedWeights: selectProductCalculatedWeights(state, ownProps.product.product_id),
    fullDataOption: selectComparisonBoardOption(state, RPM_COMPARISON_BOARD_OPTION_FULL_DATA),
    differencesOption: selectComparisonBoardOption(state, RPM_COMPARISON_BOARD_OPTION_DIFFERENCES),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ScoreCell)
