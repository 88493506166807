import React from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import WeightIcon from '../../../../../assets/svg/weight.svg'
import { selectNodeCalculatedWeight } from '../../../state/comparisonBoard/selectors'

const classNames = require('classnames')

function WeightValue({ className, calculatedWeight }) {
  const value = calculatedWeight ? calculatedWeight?.toFixed(2) : 'n.a.'

  return (
    <div className={classNames(className, 'comparison-board-table__weight')}>
      <div className="comparison-board-table__weight-icon">
        <WeightIcon width="12px" />
      </div>
      {value && <div className="comparison-board-table__weight-value">{value}</div>}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    texts: state.texts.values,
    environment: state.environment,
    calculatedWeight: selectNodeCalculatedWeight(state, ownProps.node.product_id, ownProps.node.node_definition_id),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(WeightValue)
